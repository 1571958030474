/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { equalsShallow as t } from "./lang.js";
import { trackAccess as e } from "./accessorSupport/tracking.js";
import { SimpleObservable as s } from "./accessorSupport/tracking/SimpleObservable.js";
class i {
  constructor(t, e) {
    this._observable = new s(), this._value = t, this._equalityFunction = e;
  }
  get value() {
    return e(this._observable), this._value;
  }
  set value(t) {
    this._equalityFunction(t, this._value) || (this._value = t, this._observable.notify());
  }
  mutate(t) {
    t(this._value), this._observable.notify();
  }
}
function r(e, s = t) {
  return new i(e, s);
}
export { r as signal };